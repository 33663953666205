import React from 'react'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from "gatsby"

import Navbar from '../components/Navbar'
import '../css/bootstrap.min.css'
import '../css/bootstrap-theme.min.css'
import '../css/960.css'
import '../css/lxenglish.css'
import headerbg_left from '../img/headerbg_left.gif'
import headerbg_right from '../img/headerbg_right.gif'
import headerbg_middle_default from '../img/lxenglish_banner.jpg'

const TemplateWrapper = ({ banner, children }) => (
  <StaticQuery
    query={graphql`
      query HeadingQuery {
          site {
            siteMetadata {
              title,
              description,
            }
          }
        }
    `}
    render={data => (
      <div>
        <Helmet>
          <html lang="en" />
          <title>{data.site.siteMetadata.title}</title>
          <meta name="description" content={data.site.siteMetadata.description} />
        </Helmet>

        <div id="background_container"></div>

        <div className="container_12">
          <Navbar />

          <div id="background_left"  style={{ backgroundImage: `url(${headerbg_left})` }}></div>
          <div id="background_right" style={{ backgroundImage: `url(${headerbg_right})` }}></div>
          {!!banner &&
            <div id="background_middle" className="grid_12"
              style={{
                backgroundImage: `url(${
                  !!banner.childImageSharp
                    ? banner.childImageSharp.fluid.src
                    : banner
                })`,
              }}></div>
          }
          {!banner &&
            <div id="background_middle" className="grid_12"
                style={{ backgroundImage: `url(${headerbg_middle_default})` }}></div>
          }
          <div id="mod_content" className="grid_12">
            {children}
          </div>

          <div className="clear"></div>                            
        </div>
      </div>
    )}
  />
)

export default TemplateWrapper
