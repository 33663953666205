import React from 'react'
import { Navbar, Nav, NavDropdown, MenuItem } from 'react-bootstrap';
import { MdHome } from 'react-icons/md';
import { Link, navigate } from 'gatsby'

const navBarOnSelect = (url) => {
	navigate(url);
}

const MwNavbar = () => (
	<Navbar>
		<Navbar.Header>
			<Navbar.Brand>
			 	<Link to='/'><MdHome /></Link>
			</Navbar.Brand>
		</Navbar.Header>
	    <Nav>
	      <NavDropdown id='dd-eye-train' title='The Eye Train' onSelect={navBarOnSelect}>
	        <MenuItem eventKey={'/about/eye-train-story/'}>Story</MenuItem>
	        <MenuItem eventKey={'/about/eye-train-inside/'}>Inside Eye Train</MenuItem>
	        <MenuItem eventKey={'/about/eye-train-video/'}>Video</MenuItem>
	        <MenuItem eventKey={'/about/eye-train-visited/'}>Places Visited 1997-2015</MenuItem>
	        <MenuItem eventKey={'/about/eye-train-2016-stops/'}>2016 Stops</MenuItem>
	        <MenuItem eventKey={'/about/eye-train-press/'}>Press Reports</MenuItem>
	        <MenuItem eventKey={'/about/eye-train-experience/'}>Live Stories & Experiences</MenuItem>
	      </NavDropdown>

	      <NavDropdown id='dd-training' title='Training' onSelect={navBarOnSelect}>
	      	<MenuItem header>Microsurgical Eye Training Centers</MenuItem>
	        <MenuItem eventKey={'/about/training-microsurgical-story/'}>Story</MenuItem>
	        <MenuItem eventKey={'/about/training-microsurgical-locations/'}>Locations</MenuItem>
	        <MenuItem eventKey={'/about/training-microsurgical-stats/'}>Statistics</MenuItem>
			
			<MenuItem divider />
	      	<MenuItem header>Cataract Centers</MenuItem>
	        <MenuItem eventKey={'/about/training-cataract-story/'}>Story</MenuItem>
	        <MenuItem eventKey={'/about/training-cataract-locations/'}>Locations</MenuItem>
	        <MenuItem eventKey={'/about/training-cataract-statistics/'}>Statistics</MenuItem>

			
			<MenuItem divider />
			<MenuItem header>Training Website</MenuItem>
	        <MenuItem eventKey={'/about/training-website/'}>Story</MenuItem>

	        
	        <MenuItem divider />
	        <MenuItem header>Eye Conferences</MenuItem>
	        <MenuItem eventKey={'/about/training-eye-conferences/'}>Locations</MenuItem>
	      </NavDropdown>

	      <NavDropdown id='dd-exam' title='Examination' onSelect={navBarOnSelect}>
	        <MenuItem eventKey={'/about/examination-ico/'}>ICO Examination</MenuItem>
	        <MenuItem eventKey={'/about/examination-result/'}>Examination Center</MenuItem>
	        <MenuItem eventKey={'/about/examination-fellowship/'}>International Fellowship</MenuItem>
	        <MenuItem eventKey={'/about/examination-sharings/'}>Sharing of Awards of ICO Intenational Fellowship</MenuItem>
	      </NavDropdown>

	      <NavDropdown id='dd-solar' title='Solar Hot Water' onSelect={navBarOnSelect}>
	        <MenuItem eventKey={'/about/solar-hot-water-story/'}>Story</MenuItem>
	      </NavDropdown>

	      <NavDropdown id='dd-consultant' title='Visiting Consultants' onSelect={navBarOnSelect}>
	        <MenuItem eventKey={'/about/visit-consultant-intro/'}>Introduction</MenuItem>
	        <MenuItem eventKey={'/about/visit-consultant-schedule/'}>Schedule</MenuItem>
			<MenuItem eventKey={'/about/visit-consultant-resumes/'}>Resume</MenuItem>
	      </NavDropdown>

	      <NavDropdown id='dd-disaster' title='Disaster Relief' onSelect={navBarOnSelect}>
	        <MenuItem eventKey={'/about/disaster-relive-story/'}>Story</MenuItem>
	        <MenuItem eventKey={'/about/disaster-future/'}>The Future</MenuItem>
	      </NavDropdown>
	      
	      <NavDropdown id='dd-contact-us' title='Contact Us' onSelect={navBarOnSelect}>
	        <MenuItem eventKey={'/about/contact-china-hong-kong/'}>China/Hong Kong</MenuItem>
	        <MenuItem eventKey={'/about/contact-donation-sponsorship/'}>Donation & Sponsorship</MenuItem>
	        <MenuItem eventKey={'/about/events/'}>Upcoming Events</MenuItem>
	        <MenuItem eventKey={'/about/council-members/'}>Council</MenuItem>
	      </NavDropdown>
	    </Nav>
	</Navbar>
)

export default MwNavbar